import { Component, OnInit } from '@angular/core';

import * as allData from '../data/data.json';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  private json: any;

  constructor() {
    this.json = allData['onderwerpen'];
    //console.log(this.json);
  }

  ngOnInit() {
  }

  private getKeys(a: any): string[] {
    //console.log(a);
    if (!isNullOrUndefined(a)) {
      return Object.keys(a);
    } else {
      return [];
    }
  }

  private getLength(a: any): number {
    return this.getKeys(a).length;
  }
}
