import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import {isNullOrUndefined} from 'util';


import * as allData from '../data/data.json';


@Component({
  selector: 'app-oefen',
  templateUrl: './oefen.component.html',
  styleUrls: ['./oefen.component.css']
})
export class OefenComponent implements OnInit {
  private json: any;
  private cat: string;
  private hasSelected = false;

  constructor(private route: ActivatedRoute) {
    this.json = allData['opgaven'];
  }

  getQuestions(cat: string) {
    this.cat = cat;
    if (!isNullOrUndefined(this.cat)) {
      this.hasSelected = true;
    }

    const elemInput = document.getElementsByClassName('opdrachtInput');
    const elemStats = document.getElementsByClassName('opdrachtStatus');

    [].forEach.call(elemInput, (e) => e.checked = false);
    [].forEach.call(elemStats, (e) => e.innerText = '');
  }

  private check(q: string) {
    const answer = (<HTMLInputElement>document.getElementById('question-' + this.cat + '-' + q)
      .querySelector('input[name="' + this.cat + '-' + q + '"]:checked')).value;
    const elem = document.getElementById('status-' + this.cat + '-' + q);

    if (answer === this.json[this.cat]['vragen'][q]['goed']) {
      elem.innerText = 'Correct!';
      elem.style.color = 'green';
    } else {
      elem.innerText = 'Tip: ' + this.json[this.cat]['vragen'][q]['tip'];
      elem.style.color = 'red';
    }
  }

  ngOnInit() {
    this.route.params.subscribe(
      (res) => this.getQuestions(res.cat)
    );
  }

  private getKeys(a: any): string[] {
    if (!isNullOrUndefined(a)) {
      return Object.keys(a);
    } else {
      return [];
    }
  }
}
