import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import {isNullOrUndefined} from 'util';

import * as allData from '../data/data.json';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {
  private json: any;
  private jsonPart: any;
  private cat: string;
  private sub: string;
  private isSub: boolean;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.json = allData['onderwerpen'];
  }

  initializePage(cat: string, sub: string) {
    this.cat = cat;
    this.sub = sub;
    this.isSub = !isNullOrUndefined(this.sub);
    if (this.isSub) {
      this.jsonPart = this.json[cat]['subcategories'][sub];
    } else {
      this.jsonPart = this.json[cat];
    }
  }

  ngOnInit() {
    this.route.params.subscribe(
      (res) => this.initializePage(res.cat, res.sub)
    );
  }

  private getKeys(a: any): string[] {
    if (!isNullOrUndefined(a)) {
      return Object.keys(a);
    } else {
      return [];
    }
  }

  private getTitle() {
    if (this.isSub) {
      return this.json[this.cat]['title'] + ': ' + this.jsonPart['title'];
    } else {
      return this.jsonPart['title'];
    }
  }
}
