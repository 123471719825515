import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PageComponent } from './page/page.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HomepageComponent } from './homepage/homepage.component';
import { ExamenComponent } from './examen/examen.component';
import { OefenComponent } from './oefen/oefen.component';
import { BijlagenComponent } from './bijlagen/bijlagen.component';

const appRoutes: Routes = [
  {
    path: '',
    component: HomepageComponent
  },
  {
    path: 'oefen',
    component: OefenComponent
  },
  {
    path: 'oefen/:cat',
    component: OefenComponent
  },
  {
    path: 'examen',
    component: ExamenComponent
  },
  {
    path: 'bijlagen',
    component: BijlagenComponent
  },
  {
    path: 'info/:cat',
    component: PageComponent
  },
  {
    path: 'info/:cat/:sub',
    component: PageComponent
  },
  {
    path: '**',
    component: HomepageComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    PageComponent,
    SidebarComponent,
    HomepageComponent,
    ExamenComponent,
    OefenComponent,
    BijlagenComponent,
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false }
    ),
    BrowserModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
